.modal-wrapper {
  position: fixed;
  z-index: 2147483647;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  user-select: none;
  transform-origin: top left;
}

.modal-wrapper.minimized {
  width: 200px;
}

.modal-wrapper:not(.minimized) {
  width: 700px; /* Increased width for better layout */
}

.modal-header {
  background: #2d2d2d;
  color: #fff;
  padding: 12px 16px;
  border-radius: 8px 8px 0 0;
  cursor: move;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.modal-controls {
  display: flex;
  gap: 8px;
}

.minimize-btn {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.minimize-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.modal-content {
  padding: 16px;
  background: #fff;
  border-radius: 0 0 8px 8px;
  opacity: 1;
  transition: opacity 0.3s ease;
  max-height: calc(90vh - 60px); /* Prevent modal from being too tall */
  overflow-y: auto;
}

.modal-content.transitioning {
  opacity: 0;
}

.common-elements {
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
}

.job-details {
  margin-bottom: 16px;
}

.job-details h3 {
  margin: 0 0 4px 0;
  font-size: 18px;
  color: #2d2d2d;
}

.job-details p {
  margin: 0;
  color: #666;
  font-size: 14px;
}

.selectors {
  display: flex;
  gap: 16px;
}

.select-group {
  flex: 1;
}

.select-group label {
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  color: #2d2d2d;
}

.select-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  color: #2d2d2d;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.2s ease;
}

.select-group select:hover {
  border-color: #999;
}

.select-group select:focus {
  outline: none;
  border-color: #2d2d2d;
}

.screen-container {
  position: relative;
  min-height: 200px;
  padding: 16px;
  background: #fcfdff; 
  margin: 16px 0;
  border-radius: 20px;
}

.minimized-content {
  padding: 8px 16px;
  background: #fff;
  border-radius: 0 0 8px 8px;
  font-size: 14px;
  color: #2d2d2d;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  border-top: 1px solid #e0e0e0;
  background: #f5f5f5;
  border-radius: 8px;
  position: relative;
}

/* Progress bar styles */
.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: #e0e0e0;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: #2d2d2d;
  transition: width 0.05s linear;
}

/* Updated pause button styles */
.pause-button {
  background: #2d2d2d;
  color: white;
  border: none;
  padding: 12px 24px; 
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px; 
  font-weight: 550;
  transition: all 0.2s ease;
  min-width: 120px; 
}

.pause-button:hover {
  background: #404040;
}

.pause-button.paused {
  background: #666;
}

.status-text {
  font-size: 16px;
  color: #666;
  min-width: 120px; 
  text-align: right;
}

/* Summary view styles */
.summary-container {
  padding: 20px;
  background: #fcfdff;
  border-radius: 8px;
}

.summary-container h3 {
  margin: 0 0 16px 0;
  color: #2d2d2d;
  font-size: 18px;
  text-align: center;
}

.sent-emails-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sent-email-item {
  background: #fff;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.sent-email-item .profile-name {
  font-weight: 500;
  color: #2d2d2d;
  margin-bottom: 4px;
}

.sent-email-item .profile-role {
  color: #666;
  font-size: 14px;
  margin-bottom: 4px;
}

.sent-email-item .profile-email {
  color: #666;
  font-size: 14px;
}

/* Animation classes */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}