.email-composer {
  padding: 20px;
  background: #fcfdff;
  border-radius: 8px;
}

.recipient-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background: white;
  padding: 12px;
  border-radius: 6px;
}

.label {
  font-size: 14px;
  color: #666;
  margin-right: 12px;
}

.recipient-details {
  display: flex;
  gap: 12px;
  align-items: center;
}

.recipient-details .name {
  font-weight: 500;
  color: #2d2d2d;
}

.recipient-details .role {
  color: #666;
  font-size: 14px;
}

.recipient-details .email {
  color: #666;
}

.email-preview {
  margin-bottom: 20px;
}

.email-preview textarea {
  width: 100%;
  min-height: 200px;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.6;
  resize: vertical;
  transition: border-color 0.2s ease;
  background: white;
}

.email-preview textarea:focus {
  outline: none;
  border-color: #2d2d2d;
}

.email-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: white;
  padding: 12px;
  border-radius: 6px;
}

/* Toggle switch styling */
.toggle-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-container input {
  display: none;
}

.toggle-slider {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  margin-right: 8px;
  transition: background-color 0.2s ease;
}

.toggle-slider:before {
  content: "";
  position: absolute;
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.2s ease;
}

.toggle-container input:checked + .toggle-slider {
  background-color: #2d2d2d;
}

.toggle-container input:checked + .toggle-slider:before {
  transform: translateX(20px);
}

.toggle-label {
  font-size: 14px;
  color: #2d2d2d;
}

/* Status states */
.email-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
  text-align: center;
  background: #fcfdff;
  border-radius: 8px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(45, 45, 45, 0.1);
  border-top: 3px solid #2d2d2d;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.success-icon {
  width: 48px;
  height: 48px;
  background: #2d2d2d;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  margin-bottom: 16px;
}

.email-status p {
  font-size: 16px;
  color: #2d2d2d;
  margin: 0;
}

.email-subject {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 12px;
  background-color: #f8f6f6;
  border-radius: 6px;
}

.email-subject .label {
  color: #666;
  font-weight: 500;
  font-size: 16px;
}

.subject-input {
  flex: 1;
  border: none;
  background: transparent;
  font-size: 15px;
  color: #2d2d2d;
  
}

.subject-input:read-only {
  cursor: default;
}
