.profile-selection {
  padding: 16px;
}

.selection-header {
  text-align: center;
  margin-bottom: 20px;
}

.selection-header p {
  color: #2d2d2d;
  font-size: 16px;
  font-weight: 500;
}

.profile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 0 20px;
}

.profile-card {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background-color: #fcfdff;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: all 0.2s ease;
}

.profile-card:hover {
  border-color: #2d2d2d;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.profile-card.selected {
  border-color: #2d2d2d;
  background-color: #fcfdff;
}

/* Place third card below first card */
.profile-card:nth-child(3) {
  grid-column: 1;
  grid-row: 2;
}

/* Custom checkbox styling */
.checkbox-container {
  position: relative;
  padding-right: 16px;
  cursor: pointer;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 2px solid #2d2d2d;
  border-radius: 4px;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #f5f5f5;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #2d2d2d;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.profile-info {
  flex: 1;
  margin-left: 24px;
}

.profile-name {
  font-size: 16px;
  font-weight: 500;
  color: #2d2d2d;
  margin-bottom: 8px;
}

.profile-role {
  font-size: 14px;
  color: #666;
  margin-bottom: 6px;
}

.profile-email {
  font-size: 14px;
  color: #666;
}

/* Loading state */
.profile-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 0;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #2d2d2d;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Loading text */
.profile-loading p {
  color: #2d2d2d;
  font-size: 16px;
  font-weight: 500;
}

