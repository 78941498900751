.dashboard {
  display: flex;
  flex-direction: row;
  width: 100vw;
}

.dashboardLeft {
  background-color: #f7f7f7;
  width: 280px;
  max-width: 400px;
}

.dashboardRight {
  display: grid;
  width: 100vw;
  height: 100vh;
}
